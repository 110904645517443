import React, { useState } from 'react'
import './css/topBtn.css'
import { IoIosArrowUp } from "react-icons/io";


function TopButton() {
    const [appear, setAppear] = useState(false);
    const [scrollValue, setScrollValue] = useState(0);
    const [pow, setPow] = useState(0);
    const [height, setHeight] = useState(0);


    window.addEventListener("scroll", (e) => {
        setHeight(document.documentElement.scrollHeight - document.documentElement.clientHeight);
        setPow(document.documentElement.scrollTop);
        setScrollValue (Math.round((pow * 100) / height));


        if(window.scrollY > 150){
            setAppear(true);
        }else{
            setAppear(false);
        }
    });

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
    }

  return (
    <div className={appear === true ? ' progress activate' : 'progress'} style={{ background: `conic-gradient(#525252 ${scrollValue + 1}%, #d7d7d7 ${scrollValue + 1}%)`}} onClick={goToTop}>
        <span className='progress-value'><IoIosArrowUp /></span>
    </div>
  )
}

export default TopButton