import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { FiPlus } from "react-icons/fi";
import { OnlyActive } from '../../contexts/OnlyActive';
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp';

function First() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(true);

  return (
    <div className='firstActivity1'>
      <ScrollUp />
      <div className='firstActivity'>
        <SideMenu/>

      <div className='firstActContent'>

      <h1>Вещно право</h1>

      <div className='activitiesImgDivB'>
      <img src={require('../../images/firstArea.jpg')} alt=""/>
      </div>
      
      <div className='moreTextView'>
        <ul>
          <li><i className='iconInFront'><FiPlus/></i> Правна консултация при сделки с недвижими имоти;</li>
          <li><i className='iconInFront'><FiPlus/></i> Подготовка на документи, касаещи покупко- продажба на недвижим имот;</li>
          <li><i className='iconInFront'><FiPlus/></i> Съдействие пред нотариус;</li>
          <li><i className='iconInFront'><FiPlus/></i> Изготвяне на  проекти за нотариални актове за продажба, дарение, 
          прехвърляне на имот срещу гледане и издръжка, замяна, констативни нотариални актове, нотариални актове по 
          учредяване право на строеж, учредяване право на ползване, ипотеки и други;</li>
          <li><i className='iconInFront'><FiPlus/></i> Вписване на завещания;</li>
          <li><i className='iconInFront'><FiPlus/></i> Изготвяне на договори, споразумения, пълномощни и завещания.</li>
          <li><i className='iconInFront'><FiPlus/></i> Консултации и процесуално представителство по дела с вещноправни интереси.</li>
          <li><i className='iconInFront'><FiPlus/></i> Защита на интереси при нарушено владение.</li>
          <li><i className='iconInFront'><FiPlus/></i> Делба на недвижим имот – извънсъдебна и съдебна;</li>
        </ul>
      </div>


      </div>

      </div>

      {/* <ServicesLawyer/> */}

      <BasicOverFooter/>
    </div>
  )
}

export default First