import React, { useContext } from 'react'
import './css/style.css'
import ScrollUp from '../../Common/ScrollUp'
import { OnlyActive } from '../../contexts/OnlyActive'
import SideMenuMediation from '../../components/SideMenuMediation'
import BasicOverFooter from '../../components/BasicOverFooter'


function SecondMediation() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(true);
  return (
    <div className='firstActivity1'>
    <ScrollUp />
    <div className='firstActivity'>
      <SideMenuMediation/>

    <div className='firstActContent'>

    <h1>Процедура по медиация</h1>

    <div className='moreTextView'>
      <ul>
        {/* <li><i className='iconInFront'><FiPlus/></i> Правна консултация при сделки с недвижими имоти;</li>
        <li><i className='iconInFront'><FiPlus/></i> Подготовка на документи, касаещи покупко- продажба на недвижим имот;</li>
        <li><i className='iconInFront'><FiPlus/></i> Съдействие пред нотариус;</li>
        <li><i className='iconInFront'><FiPlus/></i> Изготвяне на  проекти за нотариални актове за продажба, дарение, 
        прехвърляне на имот срещу гледане и издръжка, замяна, констативни нотариални актове, нотариални актове по 
        учредяване право на строеж, учредяване право на ползване, ипотеки и други;</li>
        <li><i className='iconInFront'><FiPlus/></i> Вписване на завещания;</li>
        <li><i className='iconInFront'><FiPlus/></i> Изготвяне на договори, споразумения, пълномощни и завещания.</li>
        <li><i className='iconInFront'><FiPlus/></i> Консултации и процесуално представителство по дела с вещноправни интереси.</li>
        <li><i className='iconInFront'><FiPlus/></i> Защита на интереси при нарушено владение.</li>
        <li><i className='iconInFront'><FiPlus/></i> Делба на недвижим имот – извънсъдебна и съдебна;</li> */}
      </ul>

      <div className='activitiesImgDivB'>
    <img src={require('../../images/sixthMediation.jpg')} alt=""/>
    </div>

      <p><strong style={{display: "block"}}>1.	Начало на медиацията</strong>
      За започване на процедурата по медиация е необходимо да изпратите съобщение 
      чрез контактната ни форма в сайта или  по имейл, както и да се обадите по 
      телефон или да посетите на място офиса, където попълните/изпратите Заявление за медиация. 
      </p>

      <p><strong style={{display: "block"}}>2.	Уведомяване на другата страна </strong>
      След получаване на Вашето запитване за медиация, следва да се свържем с другата 
      страна по конкретния спор и да предадем Вашата покана за участие в доброволно 
      разрешаване на спора чрез медиация. Освен това ние ще й разясним и основните ползи, 
      предимства и удобствата на участието в процедурата и ще потърсим съгласието й за 
      провеждане на процедурата по медиация. Освен изпратената писмена покана за медиация, 
      ние ще се свържем с другата страна по телефон или имейла,  за да уговорим следващите 
      стъпки в процедурата по медиация.
      </p>

      <p><strong style={{display: "block"}}>3.	Съгласие на другата страна в конфликта</strong>
      При изразено съгласие на другата страна за участие в медиация, следва да организираме първата среща. 
      При несъгласие на другата страна, процедурата по медиация приключва, като се дължи само първоначална такса.
      </p>

      <p><strong style={{display: "block"}}>4.	Организационни действия преди първата среща между страните и медиатора</strong>
      След съгласието на отсрещната страна за участие в медиация, следва да получите уведомление 
      от нас относно дата, час, място и участници в провеждането на първата среща, съобразено с 
      Вашите ангажименти. Преди срещата страните могат да бъдат поканени от медиатора да 
      представят кратко становище по спора и евентуални съпровождащи документи в определен от 
      него срок. Те имат за цел запознаването на медиатора със случая. Изпращащата страна изрично
       уточнява дали становището и документите са предназначени само за медиатора или за медиатора 
       и другата страна.
      </p>

      <p><strong style={{display: "block"}}>5.	Заплащане на таксите</strong>
      Преди провеждането на първата среща се извършва и заплащането за провеждането на 
      медиацията - съгласно тарифата. Точната сума за плащане и начинът на плащане се 
      уточняват предварително, така, че да могат да бъдат взети предвид особеностите 
      на вашия случай, и да получите максимална предвидимост на разходите за процедурата. 
      Таксите могат да се поделят между страните или да бъдат заплатени по уговорка между страните.
      </p>

      <p><strong style={{display: "block"}}>6.	Начало на първата среща</strong>
      В началото на първата среща се подписва споразумението за медиация, ако то не е подписано 
      още в етапа на организирането й. С него медиаторът и страните се съгласяват за провеждането 
      на медиацията и определят условията за провеждането й. Освен това се подписва  и декларация з
      а неутралност от медиатора, както и декларация за поверителност в случай, че в медиацията 
      участват  трети лица.
      </p>

      <p><strong style={{display: "block"}}>7.	Последващи срещи</strong>
      След всяка среща медиаторът и страните се уговарят за дата на следваща среща, ако е необходима такава.
      Продължителността на срещите зависи от времето, което страните могат да отделят, което е добре да бъде предварително уточнено.
      </p>

      <p><strong style={{display: "block"}}>8.	Приключване на процедурата по медиация</strong>
      При  постигане на споразумение, страните подписват спогодба. Медиаторът  не съхранява 
      подписаната от страните спогодба, той няма ангажимент да я представя в съда. Това  
      задължение е на страните  по спора или адвокатите им. Независимо от резултата на 
      процедурата по медиация, медиаторът отразява в своя регистър на медиациите датата и 
      начина на приключването й, без подробности за съдържанието на спогодбата, като това 
      действие удостоверява края на медиацията. 
      </p>

    </div>

    <div className='lawPricesTxt'></div>


    <div className='activitiesImgDivB'>
    {/* <img src={require('../../images/firstMediation.jpg')} alt=""/> */}
    </div>

    </div>

    </div>

    <BasicOverFooter/>
  </div>
  )
}

export default SecondMediation