import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Seventh() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Административно право</h1>

        <div className='activitiesImgDivB'>
        <img src={require('../../images/seventhArea.jpg')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Правна консултация и проучване на изискванията за дейности с особен режим;</li>
            <li><i className='iconInFront'><FiPlus/></i> Подготовка на документи за кандидатстване за лицензии, концесии, разрешителни;</li>
            <li><i className='iconInFront'><FiPlus/></i> Осъществяване на правни дейности във връзка процедури за вписване в административни регистри;</li>
            <li><i className='iconInFront'><FiPlus/></i> Извършване на проверки и упражняване на контрол;</li>
            <li><i className='iconInFront'><FiPlus/></i> Процесуално представителство в административни производства;</li>
            <li><i className='iconInFront'><FiPlus/></i> Оспорване на административни актове;</li>
          </ul>
        </div>
  
        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Seventh