import React from 'react'
// import { Link } from 'react-router-dom';
import { saveAs } from "file-saver";
import file from '../files/Naredba1.docx'

function LalyerPricesTxt() {

  const onDownload3 = () => {
    saveAs(file, "Наредба1.docx");
  };

  return (
    <div className='lawPricesTxt'>
            <button to="#" style={{border: "0px", cursor: "pointer"}} onClick={onDownload3} className="blog-post_cta">Изтегли наредба</button>

      </div>
  )
}

export default LalyerPricesTxt