import React, { useState, useContext, useRef, useEffect } from 'react'
import './css/menu.css'
import { GiHamburgerMenu } from "react-icons/gi"
import { AiOutlineClose } from "react-icons/ai"
import { Link, NavLink } from 'react-router-dom'
import { OnlyActive } from '../contexts/OnlyActive'

function Menu() {
  const { isActive } = useContext(OnlyActive);

  // if(window.innerWidth <= 1010){
  //   setIsActive(true);
  // }else{
  //   setIsActive(false);
  // }

    const [scrolled, setScrolled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [checkedBtn, setCheckedBtn] = useState(false);
    // const [mobileReady, setMobileReady] = useState(false);

      // if(window.innerWidth <= 1265){
      //   setMobileReady(true);
      // }else{
      //   setMobileReady(false);
      // }

    const popup = useRef(null);
    const trigger = useRef(null);
    //const [opened, setOpened] = useState(false);

    useEffect(() => {
      const clickHandler = ({ target }) => {
        if (!popup.current) return;
        if (!popupOpen || popup.current.contains(target) || trigger.current.contains(target)) return;
        setPopupOpen(false);
      };
      document.addEventListener('click', clickHandler);
      return () => document.removeEventListener('click', clickHandler);
    }, [popupOpen]);

    window.addEventListener("scroll", (e) => {
      if(window.scrollY > 50){
          setScrolled(true);
      }else{
          setScrolled(false);
      }

  });

  const setTrueCheck = () => {
    setCheckedBtn(true);
  }
  const closeCheck = () => {
    setCheckedBtn(false);
  }

  return (
    // className={ scrolled || isActive === true ? 'sticky' : ''}
<nav className={isActive === true ? 'stickyReady' : (scrolled === true ? 'sticky' : '')}>
{/* {popupOpen ? "" : ""} */}
    <div className="wrapper">
    <div className="logo">
        <Link to="/">
          {window.innerWidth <= 1265 || isActive ? <img src={require('../images/ml2t.png')} className='logoMenuImg' alt=""/> : (isActive === true ? '' : (scrolled === true ? <img src={require('../images/ml2t.png')} className='logoMenuImg' alt=""/> : 
          <img src={require('../images/whiteLogo.png')} className='logoMenuImg' alt=""/>))}
        </Link></div>

      <input type="radio" name="slider" id="menu-btn" onChange={setTrueCheck} checked={checkedBtn}/>
      <input type="radio" name="slider" id="close-btn" onChange={closeCheck}/>
      <ul className="nav-links">
        <label for="close-btn" className="btn close-btn" onClick={() => setPopupOpen(false)}><AiOutlineClose /></label>
        <li><NavLink onClick={closeCheck} to="/">Начало</NavLink></li>
        <li><NavLink onClick={closeCheck} to="/AboutFirm">За кантората</NavLink></li>
        <li>
          <NavLink to="/Activities" className="desktop-item">Сфери на дейност</NavLink>
          <input type="checkbox" id="showMega" />
          <label for="showMega" className="mobile-item">Сфери на дейност</label>
          <div className="mega-box">
            <div className="content">

              <div className="row">
                {/* <header>Design Services</header> */}
                <ul className="mega-links">
                  <li><NavLink onClick={closeCheck} to="/Activities/First">Вещно право</NavLink></li>
                  <li><NavLink onClick={closeCheck} to="/Activities/Second">Трудово право</NavLink></li>
                  <li><NavLink onClick={closeCheck} to="/Activities/Third">Облигационно право</NavLink></li>
                </ul>
              </div>
              <div className="row">
                {/* <header>Email Services</header> */}
                <ul className="mega-links">
                  <li><NavLink onClick={closeCheck} to="/Activities/Fourth">Наказателно право</NavLink></li>
                  <li><NavLink onClick={closeCheck} to="/Activities/Fifth">Осигурително право</NavLink></li>
                  <li><NavLink onClick={closeCheck} to="/Activities/Sixth">Търговско право</NavLink></li>
                </ul>
              </div>
              <div className="row">
                {/* <header>Security services</header> */}
                <ul className="mega-links">
                  <li><NavLink onClick={closeCheck} to="/Activities/Seventh">Административно право</NavLink></li>
                  <li><NavLink onClick={closeCheck} to="/Activities/Eighth">Семейно право</NavLink></li>
                  <li><NavLink onClick={closeCheck} to="/Activities/Ninth">Данъчно право</NavLink></li>
                </ul>
              </div>
              <div className="row">
                {/* <header>Гражданско право</header> */}
                <ul className="mega-links">
                  <li><NavLink onClick={closeCheck} to="/Activities/Tenth">Наследствено право</NavLink></li>
                  <li><NavLink onClick={closeCheck} to="/Activities/Eleventh">Гражданско право</NavLink></li>
                  <li><NavLink onClick={closeCheck} to="/Activities/Twelfth">Закон за домашното насилие</NavLink></li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li>
          <NavLink to="/Mediation" className="desktop-item">Медиация</NavLink>
          <input type="checkbox" id="showDrop" />
          <label for="showDrop" className="mobile-item">Медиация</label>
          <ul className="drop-menu">
            <li><NavLink onClick={closeCheck} to="/Mediation/First">Медиация</NavLink></li>
            <li><NavLink onClick={closeCheck} to="/Mediation/Second">Процедура по медиация</NavLink></li>
            <li><NavLink onClick={closeCheck} to="/Mediation/Third">Семейна медиация</NavLink></li>
            <li><NavLink onClick={closeCheck} to="/Mediation/Fourth">Бизнес медиация</NavLink></li>
            <li><NavLink onClick={closeCheck} to="/Mediation/Fifth">Медиация в колектива</NavLink></li>
          </ul>
        </li>

        <li>
          <NavLink onClick={closeCheck} to="/Prices/Law" className="desktop-item">Цени</NavLink>
          <input type="checkbox" id="showDropTwo" />
          <label for="showDropTwo" className="mobile-item">Цени</label>
          <ul className="drop-menu">
            <li><NavLink onClick={closeCheck} to="/Prices/Law">Цени за адвокатски услуги</NavLink></li>
            <li><NavLink onClick={closeCheck} to="/Prices/Mediation">Цени за медиация</NavLink></li>
          </ul>
        </li>
        {/* <li><NavLink to="/Prices">Цени</NavLink></li> */}
        <li><NavLink onClick={closeCheck} to="/Blog">Блог</NavLink></li>
        <li><NavLink onClick={closeCheck} to="/Contacts">Контакти</NavLink></li>
        <li><a onClick={closeCheck} className='hrefPhone' href='tel:+359878523343'>0878523343</a></li>
        {/* <li>.</li> */}
      </ul>
      <label for="menu-btn" className="btn menu-btn" ref={trigger} onClick={() => setPopupOpen(true)}><GiHamburgerMenu /></label>
      </div></nav>
    )
}

export default Menu