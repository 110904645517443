import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Third() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Облигационно право</h1>

        <div className='activitiesImgDivB'>
        <img src={require('../../images/thirdArea.jpg')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Консултиране и изготвяне на договори;</li>
            <li><i className='iconInFront'><FiPlus/></i> Съдействие при воденето на преговори;</li>
            <li><i className='iconInFront'><FiPlus/></i> Предявяване на иск за нищожност или унищожаемост на сделки;</li>
            <li><i className='iconInFront'><FiPlus/></i> Предявяване на иск за неизпълнени договорни задължения;</li>
            <li><i className='iconInFront'><FiPlus/></i> Предявяване на иск за неоснователно обогатяване;</li>
            <li><i className='iconInFront'><FiPlus/></i> Предявяване на иск за непозволено увреждане;</li>
            <li><i className='iconInFront'><FiPlus/></i> Предявяване на иск за налагане на обезпечения за вземания;</li>
            <li><i className='iconInFront'><FiPlus/></i> Прехвърляне на вземания и встъпване в дълг</li>
            <li><i className='iconInFront'><FiPlus/></i> Изготвяне на договор за наем, заем, цесия, влог, продажба, дарение, замяна и други;</li>
            <li><i className='iconInFront'><FiPlus/></i> Разваляне на алеаторни договори /за гледане и издръжка/;</li>
            <li><i className='iconInFront'><FiPlus/></i> Съдействие при договаряне на обезщетения във връзка с причинени имуществени и неимуществени вреди по повод пътно-транспортни произшествия /ПТП/.</li>
          </ul>
        </div>
  
        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Third