import React, { useState, useContext } from 'react'
import { OnlyActive } from '../../contexts/OnlyActive'
import './contacts.css'
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import ScrollUp from '../../Common/ScrollUp'
import emailjs from '@emailjs/browser'

function Contacts() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [agreed, setAgreed] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [agreedError, setAgreedError] = useState(false);
    const [emailVerification, setEmailVerification] = useState(true);
    const [send, setSend] = useState(false);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    //const isValidEmail = emailRegex.test(email);

    const nameChange = (e) => {
        setName(e.target.value);
    }

    const emailChange = (e) => {
        setEmail(e.target.value);
    }

    const phoneChange = (e) => {
        setPhone(e.target.value); 
    }

    const messageChange = (e) => {
        setMessage(e.target.value); 
    }

    const checkboxChange = (e) => {
        setAgreed(!agreed);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(name === ''){
            setNameError(true);
        }else{
            setNameError(false);
        }

        if(email === ''){
            setEmailError(true);
            setEmailVerification(true);
        }else{
            setEmailError(false);
            setEmailVerification(emailRegex.test(email));
            // console.log(emailRegex.test(email));
        }

        if(phone === ''){
            setPhoneError(true);
        }else{
            setPhoneError(false);
        }

        if(message === ''){
            setMessageError(true);
        }else{
            setMessageError(false);
        }

        if(agreed === false){
            setAgreedError(true);
        }else{
            setAgreedError(false);
        }

        if(name !== '' && email !== '' && phone !== '' && message !== '' && agreed && emailRegex.test(email)){
            const serviceId = 'service_7yji6pl';
            const templateId = 'template_qgpqipd';
            const publicKey = 'wIWUTOKKjnYx8VdJ-';

            const templateParams = {
                from_name: name,
                from_email: email,
                from_phone: phone,
                message: message
            };

            emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((response) => {
                console.log("succes", response);
                setSend(true);
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
                setAgreed(false);
            })
            .catch((error) => {
                console.log("err", error);
            });
        }else{
            setSend(false);
        }

    }

  return (
    <div className='contactPage'>
        <ScrollUp />
        <div className='contactImgBackground'></div>
        <section className='contact'>
            <div className='contactContent'>
                <h1 className='titleTag'><span>Контакти</span></h1>
            </div>
            <div className='contactContainer'>
                <div className='contactInfo'>
                    <div className='leftBox'>
                        <div className='iconBoxDiv'><FaLocationDot/></div>
                        <div className='textBoxDiv'>
                            <h3>Адрес</h3>
                            <p>Враца, Лукашов №5, <br></br>ет2, Офис 13</p>
                        </div>
                    </div>
                    <div className='leftBox'>
                        <div className='iconBoxDiv'><FaPhone/></div>
                        <div className='textBoxDiv'>
                            <h3>Телефон</h3>
                            <p>+359 878 523 343</p>
                        </div>
                    </div>
                    <div className='leftBox'>
                        <div className='iconBoxDiv'><MdEmail/></div>
                        <div className='textBoxDiv'>
                            <h3>E-mail</h3>
                            <p>adv.cenova@gmail.com</p>
                        </div>
                    </div>
                </div>
                <div className='contactForm'>
                    <form>
                        <h2>Изпрати съобщение</h2>
                        { nameError || emailError || phoneError || messageError || agreedError ? 
                                            <ul>
                                            <p><strong>Моля попълнете оставащите полета: </strong></p>
                                            {nameError ? <li>Вашето име</li> : ''}
                                            {emailError ? <li>Вашият e-mail</li> : ''}
                                            {phoneError ? <li>Вашивт телефон</li> : ''}
                                            {messageError ? <li>Вашето съобщение</li> : ''}
                                            {agreedError ? <li>Вашето съгласие</li> : ''}
                                        </ul> :''}

                        {emailVerification ? '' : 
                        <ul>
                            <p><strong>Моля въведете коректен Email</strong></p>
                        </ul>
                        }

                        {send ? 
                        <ul>
                            <p><strong>Успешно изпратихте съобщението си!</strong></p>
                        </ul> : ''
                        }

                        <div className='inputBox'>
                            <input type='text' name='' required='required' onChange={nameChange} value={name}/>
                            <span>Вашето име</span>
                        </div>
                        <div className='inputBox'>
                            <input type='text' name='' required='required' onChange={emailChange} value={email}/>
                            <span>Вашият e-mail</span>
                        </div>
                        <div className='inputBox'>
                            <input type='text' name='' required='required' onChange={phoneChange} value={phone}/>
                            <span>Вашият телефон</span>
                        </div>
                        <div className='inputBox'>
                            <textarea required='required' rows={5} onChange={messageChange} value={message}></textarea>
                            <span>Вашето съобщение</span>
                        </div>
                        <div className='checkBoxInput'>
                            <input type="checkbox" id="agreed" name="agreed" onChange={checkboxChange} checked={agreed}/>
                            <label for="agreed">Прочетох и приемам <Link to='/PrivacyPolicy'>Политиката за поверителност</Link></label>
                        </div>
                        <div className='inputBox'>
                            <input type='submit' name='' value='Изпрати' onClick={handleSubmit}/>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <div style={{width: "100%"}}>
            <iframe className='mapIframe' title='Кантора' src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=bulgaria,%20vratsa,%20lukashov%205+(My%20Business%20Name)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
            <a href="https://www.maps.ie/population/">Find Population on Map</a></iframe>
        </div>
    </div>
  )
}

export default Contacts