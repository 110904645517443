import { Routes, Route } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Menu from './components/Menu';
import TopButton from './components/TopButton';
import LandingPage from './pages/LandingPage/LandingPage';
import Contacts from './pages/ContactPage/Contacts';
import ActivityMainGrid from './pages/ActivityAreasMain/ActivityMainGrid';
import Prices from './pages/PricesPage/Prices';
import AboutFirm from './pages/AboutFirm/AboutFirm';
import BlogPage from './pages/BlogPage/BlogPage';
import First from './pages/ActivityAreasPages/First';
import Second from './pages/ActivityAreasPages/Second';
import { OnlyActive } from './contexts/OnlyActive';
import { useState } from 'react';
import MediationGrid from './pages/MediationGrid/MediationGrid';
import Third from './pages/ActivityAreasPages/Third';
import Fourth from './pages/ActivityAreasPages/Fourth';
import Fifth from './pages/ActivityAreasPages/Fifth';
import Sixth from './pages/ActivityAreasPages/Sixth';
import Seventh from './pages/ActivityAreasPages/Seventh';
import Eighth from './pages/ActivityAreasPages/Eighth';
import Ninth from './pages/ActivityAreasPages/Ninth';
import Tenth from './pages/ActivityAreasPages/Tenth';
import Eleventh from './pages/ActivityAreasPages/Eleventh';
import Twelfth from './pages/ActivityAreasPages/Twelfth';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ScrollUp from './Common/ScrollUp';
import FirstMediation from './pages/MediationAreas/First';
import SecondMediation from './pages/MediationAreas/Second';
import ThirdMediation from './pages/MediationAreas/Third';
import FourthMediation from './pages/MediationAreas/Fourth';
import FifthMediation from './pages/MediationAreas/Fifth';
import LawPrices from './pages/PricesPage/LawPrices';
import MediationPrices from './pages/PricesPage/MediationPrices';
// import SecondMenu from './components/SecondMenu';

function App() {
  const [isActive, setIsActive] = useState(false);

  // if(window.innerWidth <= 1010){
  //   setIsActive(true);
  // }else{
  //   setIsActive(false);
  // }
  return (
    <div className="App">
      <OnlyActive.Provider value={{ isActive, setIsActive }}>
      <Menu />
      <TopButton/>
      <ScrollUp/>

      <Routes>
        <Route path='/' element={<LandingPage/>}/>
        <Route path='/Contacts' element={<Contacts/>}/>
        <Route path='/Activities' element={<ActivityMainGrid/>}/>
        <Route path='/Prices' element={<Prices/>}/>
        <Route path='/AboutFirm' element={<AboutFirm/>}/>
        <Route path='/Blog' element={<BlogPage/>}/>
        <Route path='/Activities/First' element={<First/>}/>
        <Route path='/Activities/Second' element={<Second/>}/>
        <Route path='/Activities/Third' element={<Third/>}/>
        <Route path='/Activities/Fourth' element={<Fourth/>}/>
        <Route path='/Activities/Fifth' element={<Fifth/>}/>
        <Route path='/Activities/Sixth' element={<Sixth/>}/>
        <Route path='/Activities/Seventh' element={<Seventh/>}/>
        <Route path='/Activities/Eighth' element={<Eighth/>}/>
        <Route path='/Activities/Ninth' element={<Ninth/>}/>
        <Route path='/Activities/Tenth' element={<Tenth/>}/>
        <Route path='/Activities/Eleventh' element={<Eleventh/>}/>
        <Route path='/Activities/Twelfth' element={<Twelfth/>}/>
        <Route path='/Mediation' element={<MediationGrid/>}/>
        <Route path='/Mediation/First' element={<FirstMediation/>}/>
        <Route path='/Mediation/Second' element={<SecondMediation/>}/>
        <Route path='/Mediation/Third' element={<ThirdMediation/>}/>
        <Route path='/Mediation/Fourth' element={<FourthMediation/>}/>
        <Route path='/Mediation/Fifth' element={<FifthMediation/>}/>
        <Route path='/Prices/Law' element={<LawPrices/>}/>
        <Route path='/Prices/Mediation' element={<MediationPrices/>}/>
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>}/>
      </Routes>

      <Footer/>
      </OnlyActive.Provider>
    </div>
  );
}

export default App;
