import React, { useContext } from 'react'
import './css/style.css'
import SideMenuMediation from '../../components/SideMenuMediation'
import BasicOverFooter from '../../components/BasicOverFooter'
import ScrollUp from '../../Common/ScrollUp'
import { FiPlus } from 'react-icons/fi'
import { OnlyActive } from '../../contexts/OnlyActive'

function FirstMediation() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
    
  return (
    <div className='firstActivity1'>
    <ScrollUp />
    <div className='firstActivity'>
      <SideMenuMediation/>

    <div className='firstActContent'>

    <h1>Медиация</h1>

    <div className='moreTextView'>
      <ul>
        {/* <li><i className='iconInFront'><FiPlus/></i> Правна консултация при сделки с недвижими имоти;</li>
        <li><i className='iconInFront'><FiPlus/></i> Подготовка на документи, касаещи покупко- продажба на недвижим имот;</li>
        <li><i className='iconInFront'><FiPlus/></i> Съдействие пред нотариус;</li>
        <li><i className='iconInFront'><FiPlus/></i> Изготвяне на  проекти за нотариални актове за продажба, дарение, 
        прехвърляне на имот срещу гледане и издръжка, замяна, констативни нотариални актове, нотариални актове по 
        учредяване право на строеж, учредяване право на ползване, ипотеки и други;</li>
        <li><i className='iconInFront'><FiPlus/></i> Вписване на завещания;</li>
        <li><i className='iconInFront'><FiPlus/></i> Изготвяне на договори, споразумения, пълномощни и завещания.</li>
        <li><i className='iconInFront'><FiPlus/></i> Консултации и процесуално представителство по дела с вещноправни интереси.</li>
        <li><i className='iconInFront'><FiPlus/></i> Защита на интереси при нарушено владение.</li>
        <li><i className='iconInFront'><FiPlus/></i> Делба на недвижим имот – извънсъдебна и съдебна;</li> */}
      </ul>

      <div className='activitiesImgDivB'>
    <img src={require('../../images/firstMediation.jpg')} alt=""/>
    </div>

      <p>Медиацията е извънсъдебен начин за решаване на спорове.</p>

      <p>Медиацията е лесен и достъпен способ за постигане на взаимно 
        изгодно споразумение, в рамките на съдебното производство или 
        извън него. Води се от МЕДИАТОР – трето неутрално лице, имащо 
        за цел да подпомага спорещите страни за постигане на оптималното 
        разрешаване на спорните въпроси.</p>

        <p>В производство по медиация, страните сами достигат до решение 
            по спорните въпроси, осъзнавайки собствените си интереси, 
            договарят се за съдържанието на спогодбата, а съдията, след 
            като провери нейната законосъобразност, я одобрява.</p>

        <p>Чрез медиацията страните спестяват средства – при 
            постигната спогодба половината от внесената държавна 
            такса се възстановява.</p>

        <p>В производството по медиация, страните печелят време– 
            бързо насрочване на срещите, бързо и цялостно 
            разрешаване на спора, бързо и ефективно изпълнение на споразумението.</p>

        <p>В медиацията, поверителността е гарантирана – в процедурата 
            участват само медиаторът, страните и одобрени от тях лица. 
            Информацията, получена по време на срещите, е конфиденциална 
            и не може да бъде разгласявана, освен с изричното съгласие на страните.</p>

        <p>Особено внимание се обръща на емоционалната страна на спора. 
            Комуникацията и ясното предаване на посланията на страните са 
            ключови моменти за ефективното производство по медиация. Медиацията 
            се е доказала като изключително успешна в случаи, включващи силни 
            емоции, като тя дава възможност всяка от страните да изрази своя гняв. 
            Медиаторът създава конструктивна и сигурна обстановка за това. </p>

        <p>Постигайки възстановяване на отношенията, създавате основа за бъдещо партньорство.</p>

        <p>Постигате устойчивост – спогодбата, постигната в резултат на свободен избор, се спазва и изпълнява.</p>

        <p>Участието в процедурата по медиация е напълно доброволно. Затова 
            всяка от страните може да я напусне винаги, когато прецени. В 
            този случай делото в съда продължава и разглеждането му няма да 
            бъде повлияно от процедурата по медиация.</p>

        <p>В медиацията може да участва всяко пълнолетно и дееспособно лице.</p>

        <p>Медиаторът не може да извършва юридическа или друга експертна 
            консултация по време на медиацията. Адвокатите могат да участват 
            в процедурата по медиация и страните да се съветват с тях по всяко време.</p>

        <p><strong style={{display: "block"}}>В медиацията страните получават възможност да:</strong>
        <i className='iconInFront'><FiPlus/></i> 
            посочат различията си по време на преговорите. Медиаторът е обучен да 
            разбере защо съществуват различия и да помогне на страните да погледнат 
            над „пазарлъка”, за да установят съществените си интереси и открият добрите решения.
        </p>

        <p> В лични отделни срещи медиаторът ще спечели доверието, което страните 
            нямат един към друг, за да получи реална представа за условията, при 
            които биха се споразумели.</p>

        <p>Медиаторът може да помогне да се определи дали наистина съществуват 
            конкретни правни или фактически въпроси, които трябва да бъдат 
            изяснени, за да бъдат преговорите ползотворни.</p>

        <p>Да поемеш отговорност за разрешаване на собствените си проблеми, 
            вместо да ги оставяш в ръцете на някой друг, е признак на сила.</p>
    </div>

    <div className='lawPricesTxt'></div>

    </div>

    </div>

    <BasicOverFooter/>
  </div>
  )
}

export default FirstMediation