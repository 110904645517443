import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Twelfth() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Закон за защита от домашно насилие</h1>

        <div className='activitiesImgDivB'>
        <img src={require('../../images/twelfthArea.png')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Предоставяне на правна консултация на лица, пострадали от домашно насилие;</li>
            <li><i className='iconInFront'><FiPlus/></i> Изготвяне на молба за образуване на производство по издаване на заповед за защита.</li>
          </ul>
        </div>
  
        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Twelfth