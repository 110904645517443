import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Sixth() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Търговско право</h1>

        <div className='activitiesImgDivB'>
        <img src={require('../../images/sixthArea.jpg')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Консултации и съдействие при учредяване и преобразуване на търговски дружества;</li>
            <li><i className='iconInFront'><FiPlus/></i> Прехвърляне на търговски дружества/ дружествени дялове;</li>
            <li><i className='iconInFront'><FiPlus/></i> Правно консултиране воденето на търговски дейности;</li>
            <li><i className='iconInFront'><FiPlus/></i> Изготвяне на договори за управление и контрол;</li>
            <li><i className='iconInFront'><FiPlus/></i> Съдействие при провеждане на търговски преговори;</li>
            <li><i className='iconInFront'><FiPlus/></i> Консултиране при извършване на търговски сделки;</li>
            <li><i className='iconInFront'><FiPlus/></i> Съдействие при събирането на дългове от търговци;</li>
            <li><i className='iconInFront'><FiPlus/></i> Производства по несъстоятелност и ликвидация;</li>
            <li><i className='iconInFront'><FiPlus/></i> Процесуално представителство по търговски спорове пред всички съдебни инстанции;</li>
            <li><i className='iconInFront'><FiPlus/></i> Търговски регистър – вписване, промяна на обстоятелствата, заличаване.</li>
          </ul>
        </div>
  
        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Sixth