import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Tenth() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Наследствено право</h1>

        <div className='activitiesImgDivB'>
        <img src={require('../../images/tenthtArea.jpg')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Консултиране при изготвяне на завещания и завети;</li>
            <li><i className='iconInFront'><FiPlus/></i> Изчисляване на наследствени дялове и запазени части;</li>
            <li><i className='iconInFront'><FiPlus/></i> Правно съдействие при откриване на наследства;</li>
            <li><i className='iconInFront'><FiPlus/></i> Водене на искове за оспорване на завещания;</li>
            <li><i className='iconInFront'><FiPlus/></i> Съдействие при разпореждане с наследствени дялове;</li>
            <li><i className='iconInFront'><FiPlus/></i> Извършване на откази от наследство;</li>
            <li><i className='iconInFront'><FiPlus/></i> Предявяване на искове за поделяне на наследствената маса. Производство по делба на наследството;</li>
            <li><i className='iconInFront'><FiPlus/></i> Предявяване на иск за нищожност или унищожаемост на завещанието;</li>
            <li><i className='iconInFront'><FiPlus/></i> Представителство и защита в производства по намаляване на завещания и дарения, накърняващи запазената част на наследник;</li>
            <li><i className='iconInFront'><FiPlus/></i> Правна консултация относно договор за дарение или договор за издръжка и гледане. Изготвяне на договор, вписване и оспорване на договори;</li>
            <li><i className='iconInFront'><FiPlus/></i> Завеждане на съдебни дела за намаляване на завещателните разпореждания и договори за дарение при нарушение на запазената част от наследството;</li>
            <li><i className='iconInFront'><FiPlus/></i> Правно съдействие при обявяване на наследник по закон или завещание за недостоен да наследи починалия;</li>
            <li><i className='iconInFront'><FiPlus/></i> Изготвяне на искания до съда за разпореждане с движими или недвижими вещи, придобити под опис;</li>
            <li><i className='iconInFront'><FiPlus/></i> Извършване на фактически и правни действия по уточняване и събиране на наследствената маса;</li>
            <li><i className='iconInFront'><FiPlus/></i> Изготвяне на нотариални покани във връзка с наследствените правоотношения до останалите наследници/заветници, съдействие и представителство пред Частни съдебни изпълнители по за изпълнение на влезли в законова сила съдебни решения;</li>
            <li><i className='iconInFront'><FiPlus/></i> Правно съдействие с обявяване на безвестно изчезнал и консултация за всички произтичаащи правни последици;</li>
            <li><i className='iconInFront'><FiPlus/></i> Правно съдействие с обявяване на смърт и консултация за всички произтичащи правни последици;</li>
            <li><i className='iconInFront'><FiPlus/></i> Правно съдействие с оспорване на по-голям дял от наследството;</li>
          </ul>
        </div>
  
        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Tenth