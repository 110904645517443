import React from 'react'
import './css/sideMenu.css'
import { NavLink } from 'react-router-dom'

function SideMenu() {
  return (
    <div className='sideMenu'>
     <NavLink to="/Activities/First">Вещно право</NavLink>
      <NavLink to="/Activities/Second">Трудово право</NavLink>
      <NavLink to="/Activities/Third">Облигационно право</NavLink>
      <NavLink to="/Activities/Fourth">Наказателно право</NavLink>
      <NavLink to="/Activities/Fifth">Осигурително право</NavLink>
      <NavLink to="/Activities/Sixth">Търговско право</NavLink>
      <NavLink to="/Activities/Seventh">Административно право</NavLink>
      <NavLink to="/Activities/Eighth">Семейно право</NavLink>
      <NavLink to="/Activities/Ninth">Данъчно право</NavLink>
      <NavLink to="/Activities/Tenth">Наследствено право</NavLink>
      <NavLink to="/Activities/Eleventh">Гражданско право</NavLink>
      <NavLink to="/Activities/Twelfth">Закон за домашното насилие</NavLink>
    </div>
  )
}

export default SideMenu