import React, { useContext } from 'react'
import './PrivacyPolicy.css'
import ScrollUp from '../../Common/ScrollUp'
import { OnlyActive } from '../../contexts/OnlyActive'


function PrivacyPolicy() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(false);
  
  return (
    <div className='privacyPage'>
      <ScrollUp />
    <div className='privacyImgBackground'></div>

    <section className='privacyText'>
      <h1 className='privacyTitle'>Политика за поверителност</h1>

      <p>Целта на тази Политика за поверителност е да се представят правилата за обработка на лични данни.  Тези правила са 
        съставени съгласно Регламент (ЕС) 2016/679 (Общ регламент за защита на данните или ОРЗД) и Закона за защита на личните данни.</p>

        <p><strong>Лични данни</strong></p>

      <p>Чл.1. Лични данни са всички данни, които съдържат отделни информации за личните или материални отношения, например име, адрес, 
        имейл – адрес, телефонен номер, дата на раждане, възраст, пол, социално – осигурителен номер, видеозаписи, снимки, гласови записи 
        на лица, както и биометрични данни като пръстови отпечатъци.</p>

        <p>Адвокат Йоана Цветанова Ценова събира следните лични данни: име, фамилия, телефон и e-mail адрес. Събират се само такива лични 
          данни, които са необходими за изпълнението и организацията на адвокатски дейности, или които Вие сте предоставили доброволно на 
          разположение. Също така, сайтът използва бисквитки за анализ, статистика, маркетингови цели и подобряване на потребителското преживяване, 
          които са описани в настоящата Политика за поверителност.</p>

          <p>Контактна форма – Данните, събирани чрез формата за обратна връзка (Име, Фамилия, телефон и Е-mail), служат единствено, за да отговорим
             на вашите запитвания. Те са конфиденциални, не се използват с други цели и не се споделят с трети страни.</p>

      <p>Коментари – Данните, предоставяни чрез формата за коментари (Име, E-mail) се използват единствено за дискусиите в блога. Те са конфиденциални,
         не се използват с други цели и не се споделят с трети страни.</p>

         <p><strong>Мерки за сигурност</strong></p>

         <p>Чл.2. Адвокат Йоана Ценова предприема технически и организационни мерки за сигурност с цел защита на администрираните данни срещу неразрешено 
          и незаконно обработване, случайна загуба, унищожаване или увреждане на лични данни, каквито може да се изискват, като се има предвид състоянието 
          на техническо развитие, обичайната практика и законовите изисквания.</p>

          <p>Чл.3. За да проверите каква информация съхраняваме за Вас, моля свържете се с кантората на телефон 0878 52 33 43.</p>

          <p><strong>Предоставяне на лични данни на трети лица</strong></p>

<p>Чл.4. Адвокат Йоана Ценова не предоставя лични данни на трети лица без изрично и доброволно дадено съгласие на субекта на лични данни.</p>

 {/* <p> Чл.5. Субектът на лични данни има следните права:</p> */}

 <p>
 Чл.5. Субектът на лични данни има следните права:
  <ol>
    <li>Да получи информация за политиката за поверителност;</li>
    <li>Да получи потвърждение дали и какви негови лични данни се обработват, както и достъп до тях, в зависимост от техническите възможности;</li>
    <li>Да поиска коригиране на неточни лични данни, както и актуализирането и допълването им в случай на промяна;</li>
    <li>Да поиска заличаване на неговите лични данни ако е отпаднало законовото основание за обработката им или данните не са необходими;</li>
    <li>Да поиска ограничаване на обработването на неговите лични данни, както и право на възражение срещу обработването им, когато няма нормативно основание за обработване на лични данни;</li>
    <li>Посочените в настоящия раздел права субектът на лични данни може да упражни по кореспондентски път или на място в офис на кантората като отправи своята писмена заявка, с ясно посочено основанието за нея.</li>
  </ol>
 </p>

 {/* <p></p> */}
 <p>
 <strong>Информация относно компетентния надзорен орган</strong>
  <ul>
    <li>Комисия за защита на личните данни (КЗЛД)</li>
    <li>Адрес: гр. София 1592, бул. „Проф. Цветан Лазаров” № 2</li>
    <li>Телефон: +359 2 915 3 518</li>
    <li>Email: kzld@government.bg, kzld@cpdp.bg</li>
    <li>Уеб сайт: www.cpdp.bg</li>
  </ul>
 </p>


        
    </section>
    </div>
  )
}

export default PrivacyPolicy