import React, { useContext } from 'react'
import './css/style.css'
import { OnlyActive } from '../../contexts/OnlyActive'
import SideMenuMediation from '../../components/SideMenuMediation'
import BasicOverFooter from '../../components/BasicOverFooter'
import ScrollUp from '../../Common/ScrollUp'
import { FiPlus } from 'react-icons/fi'

function ThirdMediation() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(true);
  return (
    <div className='firstActivity1'>
    <ScrollUp />
    <div className='firstActivity'>
      <SideMenuMediation/>

    <div className='firstActContent'>

    <h1>Семейна медиация</h1>

    <div className='moreTextView'>
      <ul>
        {/* <li><i className='iconInFront'><FiPlus/></i> Правна консултация при сделки с недвижими имоти;</li>
        <li><i className='iconInFront'><FiPlus/></i> Подготовка на документи, касаещи покупко- продажба на недвижим имот;</li>
        <li><i className='iconInFront'><FiPlus/></i> Съдействие пред нотариус;</li>
        <li><i className='iconInFront'><FiPlus/></i> Изготвяне на  проекти за нотариални актове за продажба, дарение, 
        прехвърляне на имот срещу гледане и издръжка, замяна, констативни нотариални актове, нотариални актове по 
        учредяване право на строеж, учредяване право на ползване, ипотеки и други;</li>
        <li><i className='iconInFront'><FiPlus/></i> Вписване на завещания;</li>
        <li><i className='iconInFront'><FiPlus/></i> Изготвяне на договори, споразумения, пълномощни и завещания.</li>
        <li><i className='iconInFront'><FiPlus/></i> Консултации и процесуално представителство по дела с вещноправни интереси.</li>
        <li><i className='iconInFront'><FiPlus/></i> Защита на интереси при нарушено владение.</li>
        <li><i className='iconInFront'><FiPlus/></i> Делба на недвижим имот – извънсъдебна и съдебна;</li> */}
      </ul>

      <div className='activitiesImgDivB'>
    <img src={require('../../images/thirdMediation.jpg')} alt=""/>
    </div>

      <p>„Нормалното семейство не съществува без конфликти, освен това то трябва да бъде 
        конфликтно, ако е съставено от индивиди.“ - А. Кончаловски</p>

      <p>Ако в даден момент конфликтите във Вашето семейство са взели превес над 
        щастливите мигове и често се замисляте за раздяла или сте на категорична 
        позиция, че не бихте могли да продължите заедно, процедурата по семейна 
        медиация би могла да Ви подпомогне в намирането на справедливи решения, 
        които са в интерес на всички страни. </p>

        <p>
        В производството по семейна медиация, чрез помощта на трето независимо,  
        безпристрастно и неутрално лице /медиатор/, Вие достигате до оптимално 
        разрешаване на спорните помежду Ви въпроси, касаещи:
        
        <ul>
            <li><i className='iconInFront'><FiPlus/></i>местоживеене на децата;</li>
               <li><i className='iconInFront'><FiPlus/></i>упражняване на родителски права;</li>
               <li><i className='iconInFront'><FiPlus/></i>режим на лични отношения;</li>
               <li><i className='iconInFront'><FiPlus/></i>издръжка на децата;</li>
               <li><i className='iconInFront'><FiPlus/></i>пътуване на децата извън пределите на страната;</li>
               <li><i className='iconInFront'><FiPlus/></i>ползване на семейното жилище;</li>
               <li><i className='iconInFront'><FiPlus/></i>издръжка между съпрузите;</li>
               <li><i className='iconInFront'><FiPlus/></i>фамилно име;</li>
               <li><i className='iconInFront'><FiPlus/></i>разделяне на движими вещи, които са в условията на съсобственост;</li>
               <li><i className='iconInFront'><FiPlus/></i>разделяне на недвижими вещи, които са в условията на съсобственост;</li>
          </ul>
        </p>

        <p>Вие сте тези, които най-добре познавате себе си, Вашите деца и партньор. 
            Чрез участие в производство по семейна медиация, Вие достигате до 
            оптималните решения съобразно Вашите възможности и потребности и  
            тези на децата Ви /емоционални, имуществени и т.н./. </p>

        <p>Семейната медиация е доброволна и поверителна процедура. Медиаторът 
            е безпристрастен към страните и не преедлага решения по спора. 
            Именно защото не решава спорните въпроси, той осигурява гаранции 
            на страните, че всеки ще бъде изслушан и ще участва във вземането 
            на решения по честни правила.</p>

        <p>В бракоразводният процес, при производство по предоставяне на родителски 
            права, издръжка, разделяне на съсобственост и т.н., съдията е този, 
            който решава важните за Вас въпроси. </p>

        <p>В процедура по семейна медиация, изключвате възможността друг да решава 
            вместо Вас. Вие сте тези, които вземат решенията. </p>

        <p>Медиацията се провежда в неформална и приятна среда, в удобно за Вас време. </p>

        <p>Медиацията е фокусирана към Вашите бъдещи добри взаимоотношения и постигане на 
            споразумение, съобразно предложенията Ви един към друг и не на последно място, 
            споразумение което не противоречи на закона и морала.  </p>

        
    </div>

    <div className='lawPricesTxt'></div>


    <div className='activitiesImgDivB'>
    {/* <img src={require('../../images/firstMediation.jpg')} alt=""/> */}
    </div>

    </div>

    </div>

    <BasicOverFooter/>
  </div>
  )
}

export default ThirdMediation