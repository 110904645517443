import React from 'react'
import './css/footer.css'
import { FaHome } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaFacebookSquare } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';

function Footer() {
  let navigate = useNavigate();

  const navigateToHome = (e) => {
    e.preventDefault();
    navigate("/", {replace: false})
}

const navigateToContacts = (e) => {
  e.preventDefault();
  navigate("/Contacts", {replace: false})
}
  return (
    <footer>
    <div className="row primary">
      <div className="column about">
        <h3>Кантора</h3>
        <p>
        Осигуряване на ефективна правна защита на законните права и интереси на всеки клиент.
        </p>
      </div>
      <div className="column links">
        <h3>Полезни връзки</h3>
        <ul>
          <li>
            <Link to="/AboutFirm">За кантората</Link>
          </li>
          <li>
            <Link to="/Activities">Сфери на дейност</Link>
          </li>
          <li>
            <Link to="/Mediation">Медиация</Link>
          </li>
          <li>
            <Link to="/Prices/Mediation">Цени</Link>
          </li>
          <li>
            <Link to="/Contacts">Контакти</Link>
          </li>
        </ul>
      </div>

      <div className="column subscribe">
        <h3>Социални мрежи</h3>
        {/* <div>
          <input type="email" placeholder="E-mail" />
          <button>Абонирай се</button>
        </div> */}
        <div className="social">
          <i className="fa-brands fa-facebook-square"><FaFacebookSquare/></i>
          <i className="fa-brands fa-instagram-square" onClick={navigateToContacts}><MdEmail/></i>
          <i className="fa-brands fa-twitter-square" onClick={navigateToHome}><FaHome /></i>
        </div>
      </div>
    </div>
    <div className="secondary">
      <div>
        <p>
          <i className="fas fa-phone-alt"><FaPhone/></i>
        </p>
        <p>+359 878 523 343</p>
      </div>
      <div>
        <p><i className="fas fa-envelope"><MdEmail/></i></p>
        <p>adv.cenova@gmail.com</p>
      </div>
      <div>
        <p><i className="fas fa-map-marker-alt"><FaLocationDot/></i></p>
        <p>Враца, Лукашов №5, ет2, Офис 13</p>
      </div>
    </div>
    <div className="row copyright">
      <p>&copy; Всички права запазени АДВОКАТ ЙОАНА ЦЕНОВА | All Rights Reserved</p>
    </div>
  </footer>
  )
}

export default Footer