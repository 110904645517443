import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Eleventh() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Гражданско право</h1>

        <div className='activitiesImgDivB'>
        <img src={require('../../images/eleventhtArea.jpg')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Исково производство по общия ред на ГПК;</li>
            <li><i className='iconInFront'><FiPlus/></i> Особени искови производства;</li>
            <li><i className='iconInFront'><FiPlus/></i> Събиране на вземания по съдебен и извънсъдебен ред;</li>
            <li><i className='iconInFront'><FiPlus/></i> Процесуално представителство в съдебно-изпълнително производство;</li>
            <li><i className='iconInFront'><FiPlus/></i> Защита на длъжника от неправомерни действия на държавни и частни съдебни изпълнители;</li>
            <li><i className='iconInFront'><FiPlus/></i> Защита на накърнени потребителски права;</li>
            <li><i className='iconInFront'><FiPlus/></i> Съдебна промяна на име;</li>
          </ul>
        </div>
  
        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Eleventh