import React, { useContext } from 'react'
import './prices.css'
import { OnlyActive } from '../../contexts/OnlyActive'
import ScrollUp from '../../Common/ScrollUp'
import MediationPricesTxt from '../../components/MediationPricesTxt'

function MediationPrices() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(false);

  return (
    <div className='PricesPage'>
    <ScrollUp />
      <div className='pricesImgBackground'></div>
      <section className='pricesText'>
      <h1 className='MainTitlesPrices'>Цени за медиация</h1>

      <MediationPricesTxt />
      </section>

  </div>
  )
}

export default MediationPrices