import React from 'react'
import { FiPlus } from 'react-icons/fi'

function MediationPricesTxt() {
  return (
    <div className='mediationPricesTxt'>
    <p>	Страните заплащат регистрационна такса, такса за провеждане на медиация, както и всички разходи, свързани с медиацията.</p>

      <p><strong style={{display: "block"}}>Регистрационна такса</strong>	Страната или страните, подаващи заявлението за медиация заплащат заявлението 
      регистрационна такса в размер на 80 лв., която не полежи на възстановяване, което осигурява започване на действията по подготовка
       на медиацията. 
       <pre></pre>Регистрационната такса покрива административните разходи за първоначалното обработване на заявлението за медиация и за 
       организиране на подготвителните действия преди провеждането на медиацията. 
</p>

        <p>
          <strong>Такса за медиация</strong>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i>Цената на процедурата по медиация е 300 лв., което включва общо до три астрономически часа продължителност на процедурата.
               В случай на заявено желание за ко-медиация, страните дължат допълнително заплащане в размер на 100 лв.</li>
               <li><i className='iconInFront'><FiPlus/></i>При спор с материален интерес до 25 000 лв. – 300 лв.</li>
               <li><i className='iconInFront'><FiPlus/></i>При спор с материален интерес между 25 000 лв. и 100 000 лв. – 450 лв.</li>
               <li><i className='iconInFront'><FiPlus/></i>При спор с материален интерес между 100 000 лв. и 200 000 лв. – 650 лв.</li>
               <li><i className='iconInFront'><FiPlus/></i>При спор с материален интерес меджу 200 000 лв. и 300 000 лв. – 1000 лв.</li>
               <li><i className='iconInFront'><FiPlus/></i>При спор над 300 000 лв. – 1 300 лв.</li>
          </ul>

          При участие на повече от две страни по спора в медиацията се събира допълнителна такса в размер на 30% от размера на таксата за медиация  
          за съответния вид спор за всяка допълнителна страна.<pre></pre>
	        Отклонение от размера на таксите за медиация се допуска при спорове с много малък или със значителен имуществен интерес, или с изключителна сложност 
          на правната и фактическа обстановка, със съгласието на медиатора и въз основа на предварителна договореност между медиатора и страните. 
        </p>

        <p><strong style={{display: "block"}}>Плащане на таксата</strong>	
            Преди процедурата по медиация страните трябва да внесат регистрационна такса. След
         постигане на споразумение за участие в процедура по медиация, страните дължат заплащане на таксата за медиация, която не подлежи на връщане след 
         започването на медиацията. Първоначалната такса за медиация за три часа включва времето за провеждане на общи срещи и индивидуални срещи, които медиаторът
          може да насрочи със страните. <pre></pre>
	          При постигане на споразумение и в случай на заявено от страните желание, медиатора изготвя споразумение, за което се дължи допълнителна такса в размер на 100 лв. 
        Срок за изготвяне на споразумението- до 3 работни дни.<pre></pre>
	          В случай, че страните заявят желание постигнатото споразумение  да бъде заверено от нoтaриус, медиатора указва съдействие за това, за което се дължи допълнителна 
          такса която се определя и дължи  на нотариуса, извършил заверката.<pre></pre>
            В случай, че спорът е бил по висящо съдебно дело и следва да бъде депозиран в съда, след изготвянето на споразумението същото следва да бъде депозирано конкретния 
          съд. При заявено желание от страните, в седемдневен срок от подписване на споразумението бихме могли да предоставим същото на съда, за което се дължи допълнителна такса в размер на 50,00 лв./петдесет лева/.

</p>
      </div>
  )
}

export default MediationPricesTxt