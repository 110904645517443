import React, { useState, useContext } from 'react'
import './prices.css'
import { OnlyActive } from '../../contexts/OnlyActive'
import LalyerPricesTxt from '../../components/LalyerPricesTxt'
import MediationPricesTxt from '../../components/MediationPricesTxt'
import ScrollUp from '../../Common/ScrollUp'

const styleChecked = [
  {
    paddingBottom: "15px", 
    borderBottom: "2px solid #242526",
  }, 

];

function Prices() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(false);


  const [lawVisible, setLawVisible] = useState(false);
  const [checked, setChecked] = useState(2);



  const showLawyer = () => {
    setLawVisible(true);
    setChecked(1);
  }

  const showMediation = () => {
    setLawVisible(false);
    setChecked(2);
  }
  return (
    <div className='PricesPage'>
      <ScrollUp />
        <div className='pricesImgBackground'></div>
        <section className='pricesText'>
            <h1 className='MainTitlesPrices'><span style={checked === 1 ? styleChecked[0] : {}} className='priceSpanVisible' onClick={showLawyer}>Цени за Адвокатски услуги</span> <span className='spaceBetweenPrices'>/</span> <span className='priceSpanVisible pricesMediationSpan'  style={checked === 2 ? styleChecked[0] : {}} onClick={showMediation}>Цени за Медиация</span></h1>
            
            {lawVisible ? <LalyerPricesTxt /> : <MediationPricesTxt />}
        </section>

    </div>
  )
}

export default Prices