import React, { useContext } from 'react'
import './aboutFirm.css'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import ScrollUp from '../../Common/ScrollUp'
// import { FiPlus } from "react-icons/fi";
// import Services from '../../components/Services';

function AboutFirm() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(false);
  return (
    <div className='aboutFirm'>
        <ScrollUp />
        <div className='aboutLawyerImgBackground'></div>
        <div className='AboutContainer'>
        <div className='.entry-Text'><h1 className='MainTitles'>За кантората</h1></div>
        <div className='secondary-Text'>
            <p>
            Адвокат Йоана Ценова е член на <strong>Адвокатска колегия- Враца.</strong>
            </p>
            <p>
            Завършва право във Великотърновски университет „Св.св.Кирил и Методий“, с придобита юридическа правоспособност.
            </p>
        </div>

        <div className='aboutWrapper'>
        <img src={require('../../images/aboutFirm2.jpg')} alt=""/>
        <div className='aboutTextBox'>
            {/* <h2>Lorem ipsum dolor</h2> */}
            <p>
            Притежава богат юридически опит в различни сфери на правото. Работи като сътрудник в сферата на частното съдебно 
            изпълнение, където натрупва познания в областта на изпълнителното производство. От 2018г. заема длъжността „Юрисконсулт“
             в МБАЛ „Христо Ботев“ АД- гр.Враца, където придобива специфичен опит в сферата на търговското право, административното право,
              трудовото право, регистърно производство и обществените поръчки. 
              </p>

              <p>
              Адвокат Йоана Ценова е вписана в българския единен регистър на медиаторите към Министерство на правосъдието. Специализира 
              допълнително мастър клас по Бизнес медиация и Семейна медиация.

            </p>
        </div>
        </div>
        </div>

        {/* <Services /> */}


         <BasicOverFooter /> 
    </div>
  )
}

export default AboutFirm