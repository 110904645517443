import React from 'react'
import './css/basicOverFooter.css'
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function BasicOverFooter() {
  let navigate = useNavigate();
  
  const handleContacts = (e) => {
    e.preventDefault();
    navigate("/Contacts", {replace: false});
  }
  return (
    <div className='oferFooterBasic'>
        <div className='basicTextFooter'>
        <p>
            <strong>Получете консултация </strong>
            <strong>още сега</strong>
        </p>

        {/* <div className='basicOverFooterDownText'> */}
        <p>Изпратете ни запитването си по e-mail или се обадете</p>
        <p className='basicFooterContact' onClick={handleContacts}><i className='contactBtnFooter'><IoIosArrowForward/></i> Контакти</p>
        {/* </div> */}
        </div>
    </div>
  )
}

export default BasicOverFooter