import React, { useContext } from 'react'
import './css/style.css'
import SideMenuMediation from '../../components/SideMenuMediation'
import { OnlyActive } from '../../contexts/OnlyActive'
import BasicOverFooter from '../../components/BasicOverFooter'
import ScrollUp from '../../Common/ScrollUp'

function FifthMediation() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(true);

  return (
    <div className='firstActivity1'>
    <ScrollUp />
    <div className='firstActivity'>
      <SideMenuMediation/>

    <div className='firstActContent'>

    <h1>Медиация в колектива</h1>

    <div className='activitiesImgDivB'>
    <img src={require('../../images/secondMediation.jpg')} alt=""/>
    </div>

    <div className='moreTextView'>

      <p>Медиацията е една от най-успешните добри практики в управлението на конфликти на работното място. </p>

      <p>В процедурата по медиация работниците и/или служителите имат възможност да се срещнат в неформална 
        атмосфера, осигурена от професионален модератор. Чрез използването на различни подходи, медиатора 
        предразполага страните да споделят за проблема и да достигнат да работещи решения, които удовлетворяват
         всички страни. </p>
        
    </div>

    <div className='lawPricesTxt'></div>


    <div className='activitiesImgDivB'>
    {/* <img src={require('../../images/firstMediation.jpg')} alt=""/> */}
    </div>

    </div>

    </div>

    <BasicOverFooter/>
  </div>
  )
}

export default FifthMediation