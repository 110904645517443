import React from 'react'
import './css/sideMenu.css'
import { NavLink } from 'react-router-dom'

function SideMenuMediation() {
  return (
    <div className='sideMenu'>
    <NavLink to="/Mediation/First">Медиация</NavLink>
     <NavLink to="/Mediation/Second">Процедура по медиация</NavLink>
     <NavLink to="/Mediation/Third">Семейна медиация</NavLink>
     <NavLink to="/Mediation/Fourth">Бизнес медиация</NavLink>
     <NavLink to="/Mediation/Fifth">Медиация в колектива</NavLink>
   </div>
  )
}

export default SideMenuMediation