import React, {useContext, useRef} from 'react'
import './LandingPage.css'
import { IoIosArrowForward } from "react-icons/io";
import UpperFooter from '../../components/UpperFooter';
import { Link, useNavigate } from 'react-router-dom';
import { FiPlus } from "react-icons/fi";
import { OnlyActive } from '../../contexts/OnlyActive';
import { motion, useScroll } from "framer-motion";
import ScrollUp from '../../Common/ScrollUp';

function LandingPage() {
    let navigate = useNavigate();

    const { setIsActive } = useContext(OnlyActive);
    setIsActive(false);

    const aboutSection = useRef(null);
    const ref = useRef(null);

    const scrollDown = () => {
        window.scrollTo({
            top: aboutSection.current.offsetTop - 45,
            behavior: 'smooth',
          });
    }
    
    const navigateToContacts = (e) => {
        e.preventDefault();
        navigate("/Contacts", {replace: false})
    }

    const navigateToArea1 = (e) => {
      e.preventDefault();
      navigate("/Activities/First", {replace: false})
  }
    const navigateToArea2 = (e) => {
      e.preventDefault();
      navigate("/Activities/Fourth", {replace: false})
}
    const navigateToArea3 = (e) => {
      e.preventDefault();
      navigate("/Activities/Seventh", {replace: false})
}
    const navigateToArea4 = (e) => {
      e.preventDefault();
      navigate("/Activities/Eleventh", {replace: false})
}
    const navigateToArea5 = (e) => {
      e.preventDefault();
      navigate("/Activities/Second", {replace: false})
}
    const navigateToArea6 = (e) => {
      e.preventDefault();
      navigate("/Activities", {replace: false})
}

   useScroll({ target: ref, offset: ["end end", "start start"], });

    const container = {
      hidden: { y: 20, opacity: 0 },
      visible: {
        y: 0,
        opacity: 1
      }
    };
  return (
    <div className='LandingPage'>
      <ScrollUp />
        <section className='section-main'>
            <motion.div variants={container} transition={{ delay: 0.2 }} initial="hidden" animate="visible" className='landingText'>
                <h2>Адвокат</h2>
                <h1><strong>Йоана</strong> <strong>Ценова,</strong></h1>
                <h2> гр. Враца </h2>

                <button className='CTF1' onClick={navigateToContacts}>Запази час</button>
            </motion.div>

            <Link className="scroll-btn" onClick={scrollDown}></Link>

        </section>

        <section className='section-three' ref={aboutSection}>
            <h2 className='titleTagH2'><span>Сфери на дейност</span></h2>
            <div className='main'>

<div className="container1">
      <div className="row">

        <div className="image" onClick={navigateToArea1}>
        <img src={require('../../images/firstArea.jpg')} alt='' />
          <div className="details">
            <h2>Вещно право</h2>
          </div>
        </div>

        <div className="image" onClick={navigateToArea2}>
          <img src={require('../../images/fourthArea.jpg')} alt=""/>
          <div className="details">
            <h2>Наказателно право</h2>
          </div>
        </div>

        <div className="image" onClick={navigateToArea3}>
          <img src={require('../../images/seventhArea.jpg')} alt=""/>
          <div className="details">
            <h2>Административно право</h2>
            </div>
        </div>

      </div>
      <div className="row">
        <div className="image" onClick={navigateToArea4}>
          <img src={require('../../images/eleventhtArea.jpg')} alt=""/>
          <div className="details">
            <h2>Гражданско право</h2>
          </div>
        </div>
        <div className="image" onClick={navigateToArea5}>
          <img src={require('../../images/secondArea.jpg')} alt=""/>
          <div className="details">
            <h2>Трудово право</h2>
          </div>
        </div>
        <div className="image" onClick={navigateToArea6} style={{background: "#262626"}}>
          {/* <img src={require('../../images/grid/bli9.webp')} alt=""/> */}
          <div className="details">
            <h2><i><IoIosArrowForward/></i> Виж oще</h2>
          </div>
        </div>
      </div>

    </div>

    {/* <div className='gridClauses'>
        <h2 className='titleTagH2'><span>Как ще съдействаме с Вашия казус</span></h2>
        <section className="ps-timeline-sec">
        <div class="container">
            <ol className="ps-timeline">
                <li>
                    <div className="img-handler-top">
                        <img src={require('../../images/gifs/acc1.webp')} alt="" width={150}/>
                    </div>
                    <div className="ps-bot">
                        <p>Независимост</p>
                    </div>
                    <span className="ps-sp-top">01</span>
                </li>
                <li>
                    <div className="img-handler-bot">
                    <img src={require('../../images/gifs/acc2.webp')} alt="" width={150}/>
                    </div>
                    <div className="ps-top">
                        <p>Доверие</p>
                    </div>
                    <span className="ps-sp-bot">02</span>
                </li>
                <li>
                    <div className="img-handler-top">
                    <img src={require('../../images/gifs/acc3.webp')} alt="" width={150}/>
                    </div>
                    <div className="ps-bot">
                        <p>Почтеност</p>
                    </div>
                    <span className="ps-sp-top">03</span>
                </li>
                <li>
                    <div className="img-handler-bot">
                    <img src={require('../../images/gifs/acc4.webp')} alt="" width={150}/>
                    </div>
                    <div className="ps-top">
                        <p>Поверителност</p>
                    </div>
                    <span className="ps-sp-bot">04</span>
                </li>
            </ol>
        </div>
        </section>
    </div> */}
            </div>
        </section>

        <section className='midImage'>
        {/* <h2>Независимост</h2>
        <h2>Доверие</h2>
        <h2>Почтеност</h2>
        <h2>Поверителност</h2> */}
        <ol>
          <li>Независимост</li>
          <li>Доверие</li>
          <li>Почтеност</li>
          <li>Поверителност</li>
        </ol>
        </section>

        <section className='section-two'>
        <img src={require('../../images/justiceScale.jpg')} alt='attorney' className='describingPicTop'/>
        <div ref={ref} className='sticyTextDiv'> 


            <ul className='workDesc'>
            <li></li>
            <h2>Принципи на работа</h2>
                <li><i className='iconInFront'><FiPlus/></i> Задълбочено проучване и прилагане на индивидуални практики във всеки отделен казус</li>
                <li><i className='iconInFront'><FiPlus/></i> Ефективна защита и квалифицирана правна помощ</li>
                <li><i className='iconInFront'><FiPlus/></i> Постоянство при прилагането на действия за защита на интересите на клиента</li>
                <li><i className='iconInFront'><FiPlus/></i> Лоялност и почтеност в комуникацията и работата с клиентите</li>
                <li><i className='iconInFront'><FiPlus/></i> Пълна конфиденциалност на информацията и дискретност във взаимоотношенията с клиентите</li>

                {/* <motion.ul
            cx="50"
            cy="50"
            r="30"
            pathLength="1"
            className="indicator"
            style={{ pathLength: scrollYProgress }}
          /> */}
            </ul> </div>

            <img src={require('../../images/justiceScale.jpg')} alt='attorney' className='describingPic'/>
        </section>
        <UpperFooter/>
    </div>
  )
}

export default LandingPage