import React, { useContext } from 'react'
import './mediationGrid.css'
import { OnlyActive } from '../../contexts/OnlyActive'
import ScrollUp from '../../Common/ScrollUp'
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

function MediationGrid() {
  let navigate = useNavigate();
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(false);

  const navigateToContacts = (e) => {
    e.preventDefault();
    navigate("/Contacts", {replace: false})
}

const navigateToArea1 = (e) => {
  e.preventDefault();
  navigate("/Mediation/First", {replace: false})
}
const navigateToArea2 = (e) => {
  e.preventDefault();
  navigate("/Mediation/Second", {replace: false})
}
const navigateToArea3 = (e) => {
  e.preventDefault();
  navigate("/Mediation/Third", {replace: false})
}
const navigateToArea4 = (e) => {
  e.preventDefault();
  navigate("/Mediation/Fourth", {replace: false})
}
const navigateToArea5 = (e) => {
  e.preventDefault();
  navigate("/Mediation/Fifth", {replace: false})
}

  return (
    <div className='MediationGrid'>
      <ScrollUp />
        <div className='MediationGridBacgroundImg'></div>
        <h1 className='MainTitles'>Медиация</h1>

<div className="container1">
<div className="row">

<div className="image" onClick={navigateToArea1}>
<img src={require('../../images/firstMediation.jpg')} alt='' />
  <div className="details">
    <h2>Медиация</h2>
  </div>
</div>

<div className="image" onClick={navigateToArea2}>
  <img src={require('../../images/sixthMediation.jpg')} alt=""/>
  <div className="details">
    <h2>Процедура по медиация</h2>
  </div>
</div>

<div className="image" onClick={navigateToArea3}>
  <img src={require('../../images/thirdMediation.jpg')} alt=""/>
  <div className="details">
    <h2>Семейна медиация</h2>
    </div>
</div>

</div>
<div className="row">
<div className="image" onClick={navigateToArea4}>
  <img src={require('../../images/fourthMediation.jpg')} alt=""/>
  <div className="details">
    <h2>Бизнес медиация</h2>
  </div>
</div>
<div className="image" onClick={navigateToArea5}>
  <img src={require('../../images/secondMediation.jpg')} alt=""/>
  <div className="details">
    <h2>Медиация в колектива</h2>
  </div>
</div>
<div className="image" style={{background: "#262626"}} onClick={navigateToContacts}>
  {/* <img src={require('../../images/grid/bli9.webp')} alt=""/> */}
  <div className="details">
    <h2><i><IoIosArrowForward/></i> Заяви медиация</h2>
  </div>
</div>
</div>




</div>
    </div>
  )
}

export default MediationGrid