import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Ninth() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Данъчно право</h1>

        <div className='activitiesImgDivB'>
        <img src={require('../../images/ninthArea.jpg')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Правни консултации по данъчно-правни въпроси;</li>
            <li><i className='iconInFront'><FiPlus/></i> Оспорване на ревизионни актове по съдебен ред;</li>
            <li><i className='iconInFront'><FiPlus/></i> Процесуално представителство и правна защита при воденето на данъчни дела;</li>
          </ul>
        </div>
  
        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Ninth