import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Fourth() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Наказателно право</h1>
        
        <div className='activitiesImgDivB'>
        <img src={require('../../images/fourthArea.jpg')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Защита в досъдебното производство и извършване на процесуални действия;</li>
            <li><i className='iconInFront'><FiPlus/></i> Защита при предявяване на обвинение;</li>
            <li><i className='iconInFront'><FiPlus/></i> Защита по наложени мерки за неотклонение и процесуална принуда</li>
            <li><i className='iconInFront'><FiPlus/></i> Предявяване на частни тъжби;</li>
            <li><i className='iconInFront'><FiPlus/></i> Защита на пострадалия или неговите наследници по наказателно производство срещу извършителя;</li>
            <li><i className='iconInFront'><FiPlus/></i> Граждански искове в наказателното производство;</li>
            <li><i className='iconInFront'><FiPlus/></i> Молби за помилване и предсрочно освобождаване;</li>
            <li><i className='iconInFront'><FiPlus/></i> Производства за съдебна реабилитация;</li>
            <li><i className='iconInFront'><FiPlus/></i> Кумулация на наказания;</li>
            <li><i className='iconInFront'><FiPlus/></i> Замяна на наказание;</li>
            <li><i className='iconInFront'><FiPlus/></i> Прекъсване на изпълнението на наказанието.</li>
          </ul>
        </div>

        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Fourth