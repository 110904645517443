import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Fifth() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);
  
    return (
      <div className='firstActivity1'>
        <ScrollUp />
        <div className='firstActivity'>
          <SideMenu/>
  
        <div className='firstActContent'>
  
        <h1>Осигурително право</h1>

        <div className='activitiesImgDivB'>
        <img src={require('../../images/fifthArea.jpg')} alt=""/>
        </div>
  
        <div className='moreTextView'>
          <ul>
            <li><i className='iconInFront'><FiPlus/></i> Консултации и процесуално представителство при пенсионен, здравноосигурителен и/или социалноосигурителен казус;</li>
            <li><i className='iconInFront'><FiPlus/></i> Консултации при сключване на пенсионни/осигурителни договори;</li>
            <li><i className='iconInFront'><FiPlus/></i> Консултации, касаещи парични обезщетения по смисъла на КСО- при временна неработоспособност и трудоустрояване; общо заболяване, инвалидност и смърт; безработица и др.;</li>
            <li><i className='iconInFront'><FiPlus/></i> Консултации при регистриране на трудови злополуки.</li>
          </ul>
        </div>
  
        </div>
  
        </div>
  
        {/* <ServicesLawyer/> */}
  
        <BasicOverFooter/>
      </div>
    )
}

export default Fifth