import React from 'react'
import './css/overFooter.css'
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from 'react-router-dom';

function UpperFooter() {
  let navigate = useNavigate();
  
  const handleContacts = (e) => {
    e.preventDefault();
    navigate("/Contacts", {replace: false});
  }

  return (
  <div className='upperFooter'>
    <p className='overFooterBold'><strong>Получете</strong> консултация</p>
    <div className='overFooterImg'>
        <p className='overFooterBold'>още сега</p>
        <p className='overFooterText2 oft2'>Изпратете ни запитването си по e-mail или се обадете</p>
        <p className='overFooterText2' onClick={handleContacts}><i className='contactBtnFooter'><IoIosArrowForward/></i> Контакти</p>
        </div>
    </div>
  )
}

export default UpperFooter