import React, { useContext } from 'react'
import './activityMainGrid.css'
import { OnlyActive } from '../../contexts/OnlyActive'
import ScrollUp from '../../Common/ScrollUp'
import { useNavigate } from 'react-router-dom'

function ActivityMainGrid() {
  let navigate = useNavigate();
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(false);


  const navigateToArea1 = (e) => {
    e.preventDefault();
    navigate("/Activities/First", {replace: false})
}
  const navigateToArea2 = (e) => {
    e.preventDefault();
    navigate("/Activities/Second", {replace: false})
}
  const navigateToArea3 = (e) => {
    e.preventDefault();
    navigate("/Activities/Third", {replace: false})
}
  const navigateToArea4 = (e) => {
    e.preventDefault();
    navigate("/Activities/Fourth", {replace: false})
}
  const navigateToArea5 = (e) => {
    e.preventDefault();
    navigate("/Activities/Fifth", {replace: false})
}
  const navigateToArea6 = (e) => {
    e.preventDefault();
    navigate("/Activities/Sixth", {replace: false})
}
const navigateToArea7 = (e) => {
  e.preventDefault();
  navigate("/Activities/Seventh", {replace: false})
}
const navigateToArea8 = (e) => {
  e.preventDefault();
  navigate("/Activities/Eighth", {replace: false})
}
const navigateToArea9 = (e) => {
  e.preventDefault();
  navigate("/Activities/Ninth", {replace: false})
}
const navigateToArea10 = (e) => {
  e.preventDefault();
  navigate("/Activities/Tenth", {replace: false})
}
const navigateToArea11 = (e) => {
  e.preventDefault();
  navigate("/Activities/Eleventh", {replace: false})
}
const navigateToArea12 = (e) => {
  e.preventDefault();
  navigate("/Activities/Twelfth", {replace: false})
}
  return (
    <div className='activityGrid'>
      <ScrollUp />
        <div className='activityGridImgBackground'></div>

        <h1 className='MainTitles'>Сфери на дейност</h1>

        <div className="container1">
      <div className="row">

        <div className="image" onClick={navigateToArea1}>
        <img src={require('../../images/firstArea.jpg')} alt=""/>
          <div className="details">
            <h2>Вещно право</h2>
          </div>
        </div>

        <div className="image" onClick={navigateToArea2}>
        <img src={require('../../images/secondArea.jpg')} alt=""/>
          <div className="details">
            <h2>Трудово право</h2>
          </div>
        </div>

        <div className="image" onClick={navigateToArea3}>
        <img src={require('../../images/thirdArea.jpg')} alt=""/>
          <div className="details">
            <h2>Облигационно право</h2>
            </div>
        </div>

      </div>
      <div className="row">
        <div className="image" onClick={navigateToArea4}>
        <img src={require('../../images/fourthArea.jpg')} alt=""/>
          <div className="details">
            <h2>Наказателно право</h2>
          </div>
        </div>
        <div className="image" onClick={navigateToArea5}>
        <img src={require('../../images/fifthArea.jpg')} alt=""/>
          <div className="details">
            <h2>Осигурително право</h2>
          </div>
        </div>
        <div className="image" onClick={navigateToArea6}>
        <img src={require('../../images/sixthArea.jpg')} alt=""/>
          <div className="details">
            <h2>Търговско право</h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="image" onClick={navigateToArea7}>
        <img src={require('../../images/seventhArea.jpg')} alt=""/>
          <div className="details">
            <h2>Административно право</h2>
          </div>
        </div>
        <div className="image" onClick={navigateToArea8}>
        <img src={require('../../images/eighthArea.jpg')} alt=""/>
          <div className="details">
            <h2>Семейно право</h2>
          </div>
        </div>
        <div className="image" onClick={navigateToArea9}>
        <img src={require('../../images/ninthArea.jpg')} alt=""/>
          <div className="details">
            <h2>Данъчно право</h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="image" onClick={navigateToArea10}>
        <img src={require('../../images/tenthtArea.jpg')} alt=""/>
          <div className="details">
            <h2>Наследствено право</h2>
          </div>
        </div>
        <div className="image" onClick={navigateToArea11}>
        <img src={require('../../images/eleventhtArea.jpg')} alt=""/>
          <div className="details">
            <h2>Гражданско право</h2>
          </div>
        </div>
        <div className="image" onClick={navigateToArea12}>
        <img src={require('../../images/twelfthArea.png')} alt=""/>
          <div className="details">
            <h2>Закон за домашното насилие</h2>
          </div>
        </div>
      </div>

    </div>
    </div>
  )
}

export default ActivityMainGrid