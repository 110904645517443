import React, { useContext } from 'react'
import './activitiesCss.css'
import SideMenu from '../../components/SideMenu'
import BasicOverFooter from '../../components/BasicOverFooter'
import { OnlyActive } from '../../contexts/OnlyActive'
import { FiPlus } from "react-icons/fi";
// import ServicesLawyer from '../../components/ServicesLawyer';
import ScrollUp from '../../Common/ScrollUp'

function Second() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(true);

  return (
    <div className='firstActivity1'>
      <ScrollUp />
    <div className='firstActivity'>
      <SideMenu/>

    <div className='firstActContent'>

    <h1 className='titles'>Трудово право</h1>

    <div className='activitiesImgDivB'>
    <img src={require('../../images/secondArea.jpg')} alt=""/>
    </div>

    <div className='moreTextView'>
      <ul>
      <li className='innerLi'>Консултации на работници/служители, касаещи:</li>
    <li><i className='iconInFront'><FiPlus/></i> сключване, изменение и прекратяване на трудово правоотношение;</li>
          <li><i className='iconInFront'><FiPlus/></i> трудови спорове;</li>
          <li><i className='iconInFront'><FiPlus/></i> уволнение;</li>
          <li><i className='iconInFront'><FiPlus/></i> възстановяване на работа;</li>
          <li><i className='iconInFront'><FiPlus/></i> заплащане на дължими обезщетения;</li>
          <li><i className='iconInFront'><FiPlus/></i> ползване на отпуск;</li>
          <li><i className='iconInFront'><FiPlus/></i> незаплащане на  част от уговореното между страните трудово възнаграждение;</li>
          <li><i className='iconInFront'><FiPlus/></i> имуществена отговорност;</li>
          <li><i className='iconInFront'><FiPlus/></i> дисциплинарна отговорност;</li>
          <li><i className='iconInFront'><FiPlus/></i> специална закрила на някои категории работници и служители.</li>

          <li className='innerLi'>Консултации на работодатели, вкл. изготвяне на документи във връзка с трудовото законодателство:</li>
    <li><i className='iconInFront'><FiPlus/></i> налагане на дисциплинарни наказания;</li>
          <li><i className='iconInFront'><FiPlus/></i> сключване, изменение и прекратяване на трудово правоотношение;</li>
          <li><i className='iconInFront'><FiPlus/></i> изплащане на обезщетения;</li>
          <li><i className='iconInFront'><FiPlus/></i> правилници за вътрешен трудов ред;</li>
          <li><i className='iconInFront'><FiPlus/></i> правилници за структурата и организацията на работната заплата.;</li>
          <li><i className='iconInFront'><FiPlus/></i> отчитане на работното време.</li>
          <li><i className='iconInFront'><FiPlus/></i> Консултации при воденето на преговори между работодатели и работници/ 
          служители и техните представители за извънсъдебно решаване на трудовоправни спорове;</li>
          <li><i className='iconInFront'><FiPlus/></i> Процесуално представителство по трудовоправни спорове;</li>
          </ul>
    </div>

    </div>

    </div>


    <BasicOverFooter/>
  </div>
  )
}

export default Second