import React, { useContext } from 'react'
import './prices.css'
import { OnlyActive } from '../../contexts/OnlyActive'
import ScrollUp from '../../Common/ScrollUp'
import LalyerPricesTxt from '../../components/LalyerPricesTxt'

function LawPrices() {
  const { setIsActive } = useContext(OnlyActive);
  setIsActive(false);
  
  return (
    <div className='PricesPage'>
    <ScrollUp />
      <div className='pricesImgBackground'></div>
      <section className='pricesTextLaw'>
      <h1 className='MainTitlesPrices'>Цени за Адвокатски услуги</h1>

      <LalyerPricesTxt />
      </section>

  </div>
  )
}

export default LawPrices