import React, { useContext } from 'react'
import './blogPage.css'
import { Link } from 'react-router-dom'
import { OnlyActive } from '../../contexts/OnlyActive'
import ScrollUp from '../../Common/ScrollUp'

function BlogPage() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(false);

  return (
    <div className='blogPageDiv'>
        <ScrollUp />
        <div className='blogPageImgBacground'></div>

        <h1>Блог</h1>

        <div className='bodyDivBlog'>
        <div className="blogContainer">
         {/* <h2>Post</h2>  */}
        <div className="blog-post">
            <div className="blog-post_img">
                <img src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""/>
            </div>
            <div className="blog-post_info">
                <div className="blog-post_date">
                    <span>Sagar Developer</span>
                    <span>Nov 12 2021</span>
                </div>
                <h1 className="blog-post_title">Lorem ipsum dolor sit amet.</h1>
                <p className="blog-post_text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores a, tempore veniam quasi sint fugiat
                    facilis, facere, amet magnam optio velit. Laudantium et temporibus soluta, esse cupiditate aliquid
                    dicta
                    accusantium.
                </p>
                <Link to="/" className="blog-post_cta">Прочети още</Link>
            </div>
        </div>

        <div className="blog-post">
            <div className="blog-post_img">
                <img src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""/>
            </div>
            <div className="blog-post_info">
                <div className="blog-post_date">
                    <span>Sagar Developer</span>
                    <span>Nov 12 2021</span>
                </div>
                <h1 className="blog-post_title">Lorem ipsum dolor sit amet.</h1>
                <p className="blog-post_text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores a, tempore veniam quasi sint fugiat
                    facilis, facere, amet magnam optio velit. Laudantium et temporibus soluta, esse cupiditate aliquid
                    dicta
                    accusantium.
                </p>
                <Link to="/" className="blog-post_cta">Прочети още</Link>
            </div>
        </div>

        <div className="blog-post">
            <div className="blog-post_img">
                <img src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""/>
            </div>
            <div className="blog-post_info">
                <div className="blog-post_date">
                    <span>Sagar Developer</span>
                    <span>Nov 12 2021</span>
                </div>
                <h1 className="blog-post_title">Lorem ipsum dolor sit amet.</h1>
                <p className="blog-post_text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores a, tempore veniam quasi sint fugiat
                    facilis, facere, amet magnam optio velit. Laudantium et temporibus soluta, esse cupiditate aliquid
                    dicta
                    accusantium.
                </p>
                <Link to="/" className="blog-post_cta">Прочети още</Link>
            </div>
        </div>

    </div>
    </div>
    </div>
  )
}

export default BlogPage