import React, { useContext } from 'react'
import './css/style.css'
import { OnlyActive } from '../../contexts/OnlyActive'
import SideMenuMediation from '../../components/SideMenuMediation'
import BasicOverFooter from '../../components/BasicOverFooter'
import ScrollUp from '../../Common/ScrollUp'

function FourthMediation() {
    const { setIsActive } = useContext(OnlyActive);
    setIsActive(true);

  return (
    <div className='firstActivity1'>
    <ScrollUp />
    <div className='firstActivity'>
      <SideMenuMediation/>

    <div className='firstActContent'>

    <h1>Бизнес медиация</h1>

    <div className='activitiesImgDivB'>
    <img src={require('../../images/fourthMediation.jpg')} alt=""/>
    </div>

    <div className='moreTextView'>

      <p>Бизнес медиацията е насочена към доброволното решаване на спорове и провеждане на преговори между партньори и/или съдружници. </p>

      <p>Чрез участие в производство по бизнес медиация конфиденциалността за Вас и бизнес партньорите Ви е гарантирана. </p>

        <p>Търговската медиация е способ за разрешаване на възникнали конфликти между две страни, където участва и трето 
            независимо лице, медиатор, което помага и напътства страните, за да постигнат взаимно приемливо решение на 
            създалата се ситуация - един от извънсъдебните способи за разрешаване на спорове между страни. </p>

        <p>Предимствата на медиацията са няколко - конфиденциалността, която се постига при този способ за разрешаване на 
            спорове, бързината на процедурата и това, че медиацията е по-евтина от конвенционалните способи като арбитраж 
            и съдебни процедури.</p>

        <p>Това е способ, който все повече придобива популярност.
            Много често дори съдът напътства страните да търсят такъв 
            способ, който е по-бърз, по-евтин за спорещите.  </p>

        <p>Не медиаторът е този, който постановява решението, както 
            съдията или арбитъра, медиатора има функция да напътства с
            траните, да извлече от тях необходимата информация за това какво 
            стои зад действията им, за да могат страните сами да стигнат до 
            разрешаването на спора.</p>

        <p>Компаниите, които се възползват от процедура по медиация, често са дружества 
            с бизнес, в който основните играчи на пазара са няколко и всеки спор би станал 
            публичен и би повлиял на репутацията и доброто им име, както и на партньорските 
            отношения с чуждестранни фирми.  </p>

        <p>Медиаторът спомага на страните да преодолеят различията, които имат в отстояването 
            на техните крайни позиции и разбирането им за правилно и грешно. Той е мостът, 
            по който те преминават, за да преодолеят тези различия;</p>

        <p>Медиаторът е безпристрастен посредник, не съди и преценява случая предварително, като 
            чрез подходящи техники улеснява създаването и поддържането на балансирана среда;</p>

        <p>Целта е постигане на взаимно изгодно споразумение, което може да бъде извършено в обвързваща за страните форма;</p>

        <p>Процесът по медиация изцяло започва и завършва по волята и взаимното съгласие на 
            страните - всяка от страните може да си тръгне, когато пожелае;</p>

        <p>Целият процес на медиация се контролира от страните и те определят условията за провеждането му;</p>

        <p>Едно от най-важните предимства е, че всичко, което се казва и споделя по време на и в процеса на медиация, 
            е строго конфиденциално. Така търговски тайни или други интереси на страните остават защитени;
        </p>

        <p> Страните могат да се договорят за креативни решения, които никога не биха постигнали в един съдебен процес и 
            които са извън обхвата на решението на съдия или арбитър, чрез въвеждане на търговски и други специфични въпроси, 
            които не са предмет на съществуващия спор;</p>

        <p>Медиацията е процес, който е ориентиран към бъдещето и чрез него могат да се запазят и съхранят бизнес отношенията 
            между страните много по-ефективно от съдебните спорове и арбитража - две ретроспективни и антагонистични форми на
             разрешаване на спорове;</p>

        <p>Процесът по медиация е гъвкав и управляван от страните;</p>

        <p>Той е заявка за поддържане на бизнес отношения;</p>

        <p>Участието на един добър медиатор е гаранция за въвеждане на нови креативни начини и подходи за решаване на спора, 
            които страните не са могли да видят преди като възможност поради силно изострени отношения и наранено его;</p>

        <p>Фактът, че страните са потърсили медиацията означава, че те са по-склонни да започнат преговори, с цел да уредят 
            доброволно и по приятелски спора;</p>

        <p>Дискусиите и срещите с медиатора са частни и поверителни. Оферти или отстъпки, направени в процедурата по 
            медиация и споделени с медиатора по време на личните срещи не могат да бъдат разкрити от другата страна или 
            от медиатора в по-късните производства в съда, ако се стигне въпреки това до съдебен спор;</p>

        <p>Едно от най-големите предимства на медиацията е, че даже и да не се постигне споразумение, страните запазват 
            балансирани и приятелски отношения, и могат да си партнират в бъдеще;</p>

        <p>Медиация спестява в много голяма степен разходите за формален процес съдебни спорове.</p>
    </div>

    <div className='lawPricesTxt'></div>


    <div className='activitiesImgDivB'>
    {/* <img src={require('../../images/firstMediation.jpg')} alt=""/> */}
    </div>

    </div>

    </div>

    <BasicOverFooter/>
  </div>
  )
}

export default FourthMediation